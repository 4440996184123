import { Link, useTranslation } from "gatsby-plugin-react-i18next"

import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import "./QAServicesHeader.scss"
import QAServicesIcon from "./QAServicesIcon"

export default function QAServicesHeader() {
  const { t } = useTranslation("qaservices", { keyPrefix: "header" })
  return (
    <section className="qa-services-header">
      <Container fluid>
        <Row className="header-container">
          <Col className="qa-services-offering-container" xs={12} sm={12} md={7} lg={7}>
            <p className="service-secondary">{t("our services")}</p>
            <p className="service-title">{t("QA services offering")}</p>
            <Link
              className="header-button-container"
              placeholder={t("Get in touch")}
              to="/qaservices/#contactUs"
            >
              <Button className="btn-success btn-header">
                {t("Get in touch")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
