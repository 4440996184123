import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import CartierLogo from "./clients/CartierLogo"
import CCLogo from "./clients/CCLogo"
import FuturePayLogo from "./clients/FuturePayLogo"
import LogitechLogo from "./clients/LogitechLogo"
import OHVLogo from "./clients/OHVLogo"
import RenaultLogo from "./clients/RenaultLogo"
import SamsungLogo from "./clients/SamsungLogo"
import SharetecLogo from "./clients/SharetecLogo"
import GradientTitle from "./GradientTitle"
import "./QAServicesClients.scss"
import QAServicesSection from "./QAServicesSection"
function QAServicesClients() {
  const { t } = useTranslation("qaservices", { keyPrefix: "clients" })

  const content = (
    <div className="qa-services-clients-showcase">
      <div className="clients-row">
        <div className="client-icon">
          <SharetecLogo />
        </div>
        <div className="client-icon">
          <CCLogo />
        </div>
        <div className="client-icon">
          <RenaultLogo />
        </div>
        <div className="client-icon">
          <CartierLogo />
        </div>
        <div className="client-icon">
          <FuturePayLogo />
        </div>
        <div className="client-icon">
          <SamsungLogo />
        </div>
        <div className="client-icon">
          <LogitechLogo />
        </div>
        <div className="client-icon">
          <OHVLogo />
        </div>
      </div>
    </div>
  )

  return (
    <QAServicesSection
      className="qa-services-clients"
      header={
        <GradientTitle
          topString={t("title-top")}
          bottomString={t("title-bot")}
        />
      }
      content={content}
    />
  )
}

export default QAServicesClients
